import * as React from "react"
import { Link, graphql } from "gatsby"
import "../styles/blog.css"
import TextPage from "../components/TextPage"
import Icon from "../components/presentation/Icon"
import {faArrowAltCircleLeft, faArrowLeft, faArrowRight, faCalendarAlt} from "@fortawesome/free-solid-svg-icons"
import Profile from "../components/Profile"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import {useEffect, useRef, useState} from "react";

const BlogPostTemplate = ({ data, location }) => {
    const post = data.markdownRemark
    const { previous, next } = data

    const articleRef = useRef(null)
    const [read, setRead] = useState(false)

    useEffect(() => {

        const handler = () => {
            if(articleRef.current){
                const articleBottom = articleRef.current.getBoundingClientRect().bottom

                if (window.innerHeight >= articleBottom) {
                    setRead((read) => {
                        if(!read){
                            console.log("Emit tracking event: Reached bottom")
                            trackCustomEvent({
                                category: "Scroll Behavior",
                                action: "Reached Bottom"
                            })
                        }
                        return true
                    })
                }
            }

        }

        window.addEventListener("scroll", handler)

        return () => {
            window.removeEventListener("scroll", handler)
        }

    }, [articleRef])

    return (
        <TextPage heading={post.frontmatter.title}
                  thumbnail={post.frontmatter.image}>
            <article
                className="blog-post b3"
                itemScope
                itemType="http://schema.org/Article"
                ref={articleRef}
            >
                <h4 className="b4">
                    {post.frontmatter.subtitle}
                </h4>
                <div className="b3">
                    <Icon icon={faCalendarAlt} left/>
                    {post.frontmatter.date}
                </div>
                <Profile name={post.frontmatter.author} />
                <section
                    className="post"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                />
            </article>
            <nav className="blog-post-nav">
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li className="b5">
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev" className="button" style={{ color: "rgba(255,255,255,0.8)"}}>
                                <Icon icon={faArrowLeft} left/> Vorheriger Artikel
                            </Link>
                        )}
                    </li>
                    <li className="b5">
                        {next && (
                            <Link to={next.fields.slug} rel="next" className="button" style={{ color: "rgba(255,255,255,0.8)"}}>
                                Nächster Artikel <Icon icon={faArrowRight} right/>
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </TextPage>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "DD. MMMM YYYY")
        description
        author
        image
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
